import { defineStore } from "pinia";
import { st } from "./index";

export const useParams = defineStore("params", {
  state: () => {
    const params: AnyObject = {};
    return {
      params,
    };
  },
  actions: {
    setParams(params: AnyObject) {
      this.params = Object.assign(this.params, params);
    },
  },
  persist: {
    storage: st,
  },
});
