/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: [{ space_src: '111111', space_type: 'inters', space_id: 'u6914857', space_js: '' }],
    Home_dialog: [{ space_src: '111111', space_type: 'native', space_id: 'u6914860', space_js: '' }],
    Home_banner: [{ space_src: '111111', space_type: 'native', space_id: 'u6914862', space_js: '//' }],
    Topic_native: [{ space_src: '111111', space_type: 'native', space_id: 'u6914861', space_js: '//' }],
    Discover_native: [{ space_src: '111111', space_type: 'native', space_id: 'u6914861', space_js: '//' }],
    Hot_native: [{ space_src: '111111', space_type: 'native', space_id: 'u6914861', space_js: '//' }],
    Rank_native: [{ space_src: '111111', space_type: 'native', space_id: 'u6914861', space_js: '//' }],
    TopicDetail_inter: [{ space_src: '111111', space_type: 'inters', space_id: 'u6914858', space_js: '//' }],
    TopicDetail_dialog: [{ space_src: '111111', space_type: 'native', space_id: 'u6914863', space_js: '//' }],
    TopicDetail_banner: [{ space_src: '111111', space_type: 'native', space_id: 'u6914865', space_js: '//' }],
    TopicResult_inter: [{ space_src: '111111', space_type: 'inters', space_id: 'u6914859', space_js: '//' }],
    TopicResult_dialog: [{ space_src: '111111', space_type: 'native', space_id: 'u6914866', space_js: '//' }],
    TopicResult_native: [{ space_src: '111111', space_type: 'native', space_id: 'u6914869', space_js: '//' }],
    TopicResult_banner: [{ space_src: '111111', space_type: 'native', space_id: 'u6914867', space_js: '//' }],
};
