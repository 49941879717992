import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import GuideView from "../views/Guide.vue";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/index",
    name: "Guide",
    component: GuideView,
  },
  {
    path: "/",
    name: "Index",
    component: HomeView,
    redirect: "/topic",
    children: [
      {
        path: "/topic",
        name: "Home",
        component: () => import("../views/home/topic.vue"),
        meta: {
          spaceKeys: [
            "Home_inter",
            "Home_dialog",
            "Home_banner",
            "Topic_native",
          ],
        },
      },
      {
        path: "/discover",
        name: "Discover",
        component: () => import("../views/home/discover.vue"),
        meta: {
          spaceKeys: [
            "Home_inter",
            "Home_dialog",
            "Home_banner",
            "Discover_native",
          ],
        },
      },
      {
        path: "/hot",
        name: "Hot",
        component: () => import("../views/home/hot.vue"),
        meta: {
          spaceKeys: ["Home_inter", "Home_dialog", "Home_banner", "Hot_native"],
        },
      },
      {
        path: "/rank",
        name: "Rank",
        component: () => import("../views/home/rank.vue"),
        meta: {
          spaceKeys: [
            "Home_inter",
            "Home_dialog",
            "Home_banner",
            "Rank_native",
          ],
        },
      },
    ],
  },
  {
    path: "/topic-list",
    name: "TopicList",
    component: () => import("../views/TopicList.vue"),
    meta: {
      spaceKeys: [],
    },
  },
  {
    path: "/topic-detail",
    name: "TopicDetail",
    component: () => import("../views/TopicDetail.vue"),
    meta: {
      spaceKeys: [
        "TopicDetail_inter",
        "TopicDetail_dialog",
        "TopicDetail_banner",
      ],
    },
  },
  {
    path: "/topic-result",
    name: "TopicResult",
    component: () => import("../views/TopicResult.vue"),
    meta: {
      spaceKeys: [
        "TopicResult_inter",
        "TopicResult_dialog",
        "TopicResult_banner",
        "TopicResult_native",
      ],
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
